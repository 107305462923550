import React from "react"
import { navigate, Link } from "gatsby"
import { handleLogin, isLoggedIn } from "../services/auth"
import Layout from '../components/layout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


class LoginPage extends React.Component {
  state = {
    username: ``,
    password: ``,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    handleLogin(this.state).then(result => {
      if (result && result.success) {
        navigate(`/dashboard`)
      } else if (result) {
        console.error(result.message)
        // TOTO: mayhaps this should handle and display multiple errors (nice to have)
        this.setState({ password: ``, message: result.message[0].messages[0].message })
      }
    })

  }


  render() {
    if (isLoggedIn()) {
      navigate(`/dashboard`)
    }

    return (
      <Layout title={'Login'} subtitle={'HRBC'}>
        <section className="page-section about-heading" style={{ paddingTop: '5rem' }}>
          <Container>
            <div className="about-heading-content">
              <Row>
                <Col xl={9} lg={10} className="mx-auto">
                  <div className="bg-faded rounded p-5">
                    <h2 className="section-heading text-center mb-4">
                      <span className="section-heading-lower">Login</span>
                    </h2>
                    <Row>
                      <Col md={6} className="mx-auto">
                        <form
                          method="post"
                          onSubmit={event => {
                            this.handleSubmit(event)
                          }}
                        >
                          <Row noGutters>
                            {this.state.message}
                          </Row>
                          <Row noGutters>
                            <Col className="mb-3">
                              <input
                                type="text"
                                name="username"
                                placeholder="Email"
                                value={this.state.username}
                                onChange={this.handleUpdate}
                                style={{ width: '100%' }}
                              />
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col className="mb-3">
                              <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={this.state.password}
                                onChange={this.handleUpdate}
                                style={{ width: '100%' }}
                              />
                              <br />
                              <div className="float-left">
                                <Link to="/login" style={{ fontSize: '10pt' }}>Forgot Password?</Link>
                              </div>
                              <div className="float-right">
                                <Link to="/register" style={{ fontSize: '10pt' }}>Register</Link>
                              </div>
                            </Col>
                          </Row>
                          <Row noGutters>
                            <input
                              type="submit"
                              name="submit"
                              value="Login"
                              className="btn btn-primary"
                              onSubmit={console.log}
                              style={{ width: '100%' }}
                            />
                          </Row>
                        </form>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default LoginPage